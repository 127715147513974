<template>
    <div class="d-flex flex-column">
        <v-btn
            dark
            color="purple darken-2"
            small
            :loading="loading"
            @click.prevent.stop="onClick"
        >
            Импорт процедуры&nbsp;<sub>json или xml</sub>
        </v-btn>
    </div>
</template>

<script>
import { xml2json } from 'xml-js'

export default {
    props: {
        hint: { type: Boolean, default: false }
    },
    data () {
        return {
            loading: false
        }
    },
    methods: {
        async onClick () {

            let file = await this.selectFile()
            
            if (!file)
                throw new Error('File selection canceled.')

            if (file.type === 'text/xml') {
                let fileContent = await file.text()

                if (!fileContent)
                    throw new Error('Failed to read file')

                // Replace digit named tags to avoid errors on parsing xml
                fileContent = fileContent.replace(/<(\d)>/gm, `<x-$1>`)
                fileContent = fileContent.replace(/<\/(\d)>/gm, `</x-$1>`)
                
                const result = JSON.parse(xml2json(fileContent, { compact: false, spaces: 4, nativeType: true, ignoreDeclaration: true }))
                const transformedData = result.elements[0].elements.map(report => this.normalizeTransformedReport(report.elements))
                
                if (!transformedData)
                    throw new Error('Can\'t transform xml to json')

                file = this.createFile({
                    content: JSON.stringify(transformedData),
                    type: 'text/json',
                    name: file.name.replace('.xml', '.json')
                })
            }

            const result = await this.importDataCollection(file)

            this.$emit('sent', result)
        },
        normalizeTransformedReport (elements) {
            if (!elements)
                throw new Error('Missed object for proccessing.')

            return elements.reduce((result, item) => {

                if (!(item.elements && item.elements.length > 0))
                    return result

                // Replace 'x-' prefix in names that have been used to avoid problems with digit named tags
                const key = item.name.replace('x-', '').replace('Н', 'не проид.')
                const value = item.elements.length === 1 && !_.isNil(item.elements[0]?.text) ?
                                item.elements[0].text :
                                this.normalizeTransformedReport(item.elements)

                if (!_.isNil(result[key]) && !Array.isArray(result[key]))
                    result[key] = [result[key]]

                if (Array.isArray(result[key]))
                    result[key].push(value)
                else
                    result[key] = value
                
                return result
                
            }, {})
        },
        selectFile () {
            return new Promise(resolve => {
                // Создаем элемент input
                const input = document.createElement('input')
                input.type = 'file'
                input.resultept = '.json,.xml' // Разрешаем только файлы с указанным расширением 
                
                // Добавляем обработчик события на изменение (выбор файла)
                input.onchange = (event) => {
                    const file = event.target.files[0]
                    resolve(file || null)
                };

                // Имитация клика
                input.click()
            })
        },
        createFile ({ content = '', type = 'text/plain', name = 'file.txt' }) {
            const blob = new Blob([content], { type: type })
            return new File([blob], name, {  type: blob.type })
        },
        async importDataCollection (file) {
            let succeed = true
            try {
                this.loading = true
                
                const form = new FormData()
                form.append('file', file)
                
                const { success, error } = await this.$store.dispatch('fioko_data_collection/import', form)

                if (!success)
                    throw new Error(error)
                
            } catch (e) {
                console.error(e)
                succeed = false
            } finally {
                this.loading = false
            }
            return succeed
        }
    }
}
</script>